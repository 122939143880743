import React from "react";
import "./RatePizzaForm.scss";

export default function RatePizzaForm({ handleSubmit, handleChange }) {
  return (
    <form className="rate-pizza" onSubmit={handleSubmit}>
      <div className="rate-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="rate-pizza__input"
          name="taste"
          placeholder="taste"
          autocomplete="off"
          onChange={handleChange}
        ></input>
        <span className="rate-pizza__unit">/100</span>
        <div className="rate-pizza__tooltip">
          i
          <span className="rate-pizza__tooltip-text">
            This is the simplest one. Does it taste good?
          </span>
        </div>
        <p className="rate-pizza__form-error-text taste-check">
          This field is required
        </p>
      </div>
      <div className="rate-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="rate-pizza__input"
          name="leoparding"
          placeholder="leoparding"
          autocomplete="off"
          onChange={handleChange}
        ></input>
        <span className="rate-pizza__unit">/100</span>
        <div className="rate-pizza__tooltip">
          i
          <span className="rate-pizza__tooltip-text">
            These are the black spots that appear just before the pizza is
            cooked. Not burnt, but beautiful! (they taste great)
          </span>
        </div>
        <p className="rate-pizza__form-error-text leoparding-check">
          This field is required
        </p>
      </div>
      <div className="rate-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="rate-pizza__input"
          name="mouthFeel"
          placeholder="mouth feel"
          autocomplete="off"
          onChange={handleChange}
        ></input>
        <span className="rate-pizza__unit">/100</span>
        <div className="rate-pizza__tooltip">
          i
          <span className="rate-pizza__tooltip-text">
            Ew right? But this is important! Its how it feels in your mouth.
            This is hardest to rate I think.
          </span>
        </div>
        <p className="rate-pizza__form-error-text mouth-feel-check">
          This field is required
        </p>
      </div>
      <div className="rate-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="rate-pizza__input"
          name="caverns"
          placeholder="caverns"
          autocomplete="off"
          onChange={handleChange}
        ></input>
        <span className="rate-pizza__unit">/100</span>
        <div className="rate-pizza__tooltip">
          i
          <span className="rate-pizza__tooltip-text">
            This is the air bubbles in the crust, that expand when you cook the
            pizza. YOu don't want the pizza to be totally hollow, but these
            caverns are wonderful to behold!
          </span>
        </div>
        <p className="rate-pizza__form-error-text caverns-check">
          This field is required
        </p>
      </div>
      <div className="rate-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="rate-pizza__input"
          name="extensibility"
          placeholder="extensibility"
          autocomplete="off"
          onChange={handleChange}
        ></input>
        <span className="rate-pizza__unit">/100</span>
        <div className="rate-pizza__tooltip">
          i
          <span className="rate-pizza__tooltip-text">
            How easily does the pizza get stretched and shaped?
          </span>
        </div>
        <p className="rate-pizza__form-error-text extensibility-check">
          This field is required
        </p>
      </div>
      <div className="rate-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="rate-pizza__input"
          name="baseCrustiness"
          placeholder="base crustiness"
          autocomplete="off"
          onChange={handleChange}
        ></input>
        <span className="rate-pizza__unit">/100</span>
        <div className="rate-pizza__tooltip">
          i
          <span className="rate-pizza__tooltip-text">
            How well is the very base of the pizza cooked?
          </span>
        </div>
        <p className="rate-pizza__form-error-text base-crustiness-check">
          This field is required
        </p>
      </div>
      <div className="rate-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="rate-pizza__input"
          name="hotRise"
          placeholder="hot rise"
          autocomplete="off"
          onChange={handleChange}
        ></input>
        <span className="rate-pizza__unit">/100</span>
        <div className="rate-pizza__tooltip">
          i
          <span className="rate-pizza__tooltip-text">
            How well did your crust grow in the oven?
          </span>
        </div>
        <p className="rate-pizza__form-error-text hot-rise-check">
          This field is required
        </p>
      </div>
      <div className="rate-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="rate-pizza__input"
          name="rise"
          placeholder="rise"
          autocomplete="off"
          onChange={handleChange}
        ></input>
        <span className="rate-pizza__unit">/100</span>
        <div className="rate-pizza__tooltip">
          i
          <span className="rate-pizza__tooltip-text">
            How well did you pizza rise while proofing?
          </span>
        </div>
        <p className="rate-pizza__form-error-text rise-check">
          This field is required
        </p>
      </div>
      <button className="button" type="submit">
        submit
      </button>
    </form>
  );
}
