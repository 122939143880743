import React from "react";
import "./ToolItem.scss";

export default function ToolItem({ link, title, img, description }) {
  const theImg = require("../../assets/images/logo.png");
  return (
    <div className="tools__item">
      <a className="tools__item-link" href={link} target="_blank">
        <h3 className="tools__item-title">{title}</h3>
        <img src={img} alt={`${title} image`} className="tools__item-img" />
      </a>
      {description}
    </div>
  );
}
