import React from "react";
import { Link } from "react-router-dom";
import RadarChart from "../RadarChart/RadarChart";
import "./PizzaListItem.scss";

export default function PizzaListItem({
  pizzaId,
  pizzaName,
  taste,
  leoparding,
  mouthFeel,
  caverns,
  extensibility,
  baseCrustiness,
  hotRise,
  rise,
  average,
}) {
  const data = {
    name: "Pizza",
    captions: {
      // columns
      taste: "",
      leoparding: "",
      mouthFeel: "",
      caverns: "",
      extensibility: "",
      baseCrustiness: "",
      hotRise: "",
      rise: "",
    },
    chart: [
      // data
      {
        data: {
          taste: taste / 100,
          leoparding: leoparding / 100,
          mouthFeel: mouthFeel / 100,
          caverns: caverns / 100,
          extensibility: extensibility / 100,
          baseCrustiness: baseCrustiness / 100,
          hotRise: hotRise / 100,
          rise: rise / 100,
        },
        meta: { color: "#f6416c" },
      },
    ],
    size: 200,
    options: {
      className: "caption",
      textAnchor: "middle",
      fontSize: 10,
    },
  };

  return (
    <Link to={`/my-pizzas/${pizzaId}`} className="pizza-list-item">
      <h2 className="pizza-list-item__name">{pizzaName}</h2>
      <div className="pizza_-list-item__radar-chart">
        <RadarChart
          captions={data.captions}
          data={data.chart}
          size={data.size}
          options={data.options}
        />
      </div>
      <h2 className="pizza-list-item__rating">{average}/100</h2>
    </Link>
  );
}
