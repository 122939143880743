import React, { useState, useEffect } from "react";
import "./PizzaItem.scss";
import RecipeCard from "../RecipeCard/RecipeCard";
import PizzaRatings from "../PizzaRatings/PizzaRatings";
// import RadarChart from "../RadarChart/RadarChart";
import { Link } from "react-router-dom";
import { db } from "../../firebase.js";
import { doc, deleteDoc } from "firebase/firestore";
import { logout, auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";

export default function PizzaItem({ pizzaDetails }) {
  const [user, loading, error] = useAuthState(auth);

  const deletePizza = () => {
    deleteDoc(doc(db, "pizzas", pizzaDetails.docID));

    db.collection("pizzas")
      .doc(pizzaDetails.docID)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
    setTimeout(() => {
      window.location.replace("/my-pizzas/");
    }, 2500);
  };

  const exportRecipeCard = () => {
    htmlToImage
      .toPng(document.querySelector(".pizza__recipe-card"))
      .then(function (dataUrl) {
        download(dataUrl, "recipecard_libraryofpizza.png");
      });
  };

  const editDeleteButtons = () => {
    if (user) {
      return (
        <>
          <Link to={`/my-pizzas/${pizzaDetails.docID}/edit`}>
            <button className="button">edit pizza</button>
          </Link>
          <div className="pizza__delete-wrap">
            <p>Deleting a pizza cannot be undone, please be careful!</p>
            <button className="button__delete" onClick={deletePizza}>
              delete pizza
            </button>
          </div>
        </>
      );
    }
  };
  if (pizzaDetails.pizzaDoc.rated === false) {
    return (
      <article className="pizza">
        <h2>{pizzaDetails.pizzaDoc.pizzaName}</h2>
        <RecipeCard
          hydration={pizzaDetails.pizzaDoc.hydration}
          salt={pizzaDetails.pizzaDoc.salt}
          cptime={pizzaDetails.pizzaDoc.coldProvingTime}
          cptemp={pizzaDetails.pizzaDoc.coldProvingTemp}
          rtptime={pizzaDetails.pizzaDoc.warmProvingTime}
          rtptemp={pizzaDetails.pizzaDoc.warmProvingTemp}
          ballWeight={pizzaDetails.pizzaDoc.ballWeight}
          yeast={pizzaDetails.pizzaDoc.yeast}
        />
        <button className="button" onClick={exportRecipeCard}>
          download recipe card
        </button>
        <h3 className="pizza__ratings-title">Ratings</h3>
        <Link to={`/my-pizzas/${pizzaDetails.docID}/rate`}>
          <button className="button">rate pizza</button>
        </Link>
        <p className="pizza__ratings-content">
          Rate this pizza to see your pizza visualisation!
        </p>
        <div className="pizza__delete-wrap">
          <p>Deleting a pizza cannot be undone, please be careful!</p>
          <button className="button__delete" onClick={deletePizza}>
            delete pizza
          </button>
        </div>
      </article>
    );
  } else {
    return (
      <article className="pizza">
        <h2>{pizzaDetails.pizzaDoc.pizzaName}</h2>
        <RecipeCard
          hydration={pizzaDetails.pizzaDoc.hydration}
          salt={pizzaDetails.pizzaDoc.salt}
          cptime={pizzaDetails.pizzaDoc.coldProvingTime}
          cptemp={pizzaDetails.pizzaDoc.coldProvingTemp}
          rtptime={pizzaDetails.pizzaDoc.warmProvingTime}
          rtptemp={pizzaDetails.pizzaDoc.warmProvingTemp}
          ballWeight={pizzaDetails.pizzaDoc.ballWeight}
          yeast={pizzaDetails.pizzaDoc.yeast}
        />
        <button className="button--full" onClick={exportRecipeCard}>
          download recipe card
        </button>
        <PizzaRatings
          rated={pizzaDetails.pizzaDoc.rated}
          taste={pizzaDetails.pizzaDoc.taste}
          leoparding={pizzaDetails.pizzaDoc.leoparding}
          mouthFeel={pizzaDetails.pizzaDoc.mouthFeel}
          caverns={pizzaDetails.pizzaDoc.caverns}
          extensibility={pizzaDetails.pizzaDoc.extensibility}
          baseCrustiness={pizzaDetails.pizzaDoc.baseCrustiness}
          hotRise={pizzaDetails.pizzaDoc.hotRise}
          rise={pizzaDetails.pizzaDoc.rise}
          average={pizzaDetails.pizzaDoc.average}
        />
        {editDeleteButtons()}
      </article>
    );
  }
}
