import React, { useEffect } from "react";
import Loading from "../../components/Loading/Loading";
import RecipeCard from "../../components/RecipeCard/RecipeCard";
import { Link } from "react-router-dom";
import "./index.scss";
import logo from "../../assets/images/logo.png";
import twoCookedPizzas from "../../assets/images/2-cooked-pizzas.jpg";
import margherita from "../../assets/images/margherita.jpg";
import cookedPizza from "../../assets/images/cooked-pizza.jpg";
import halfPizza from "../../assets/images/half-pizza.jpg";
import doughKneading from "../../assets/images/dough-kneading.jpg";
import pizzaOven from "../../assets/images/pizza-oven.jpg";
import pizzaBase from "../../assets/images/pizza-base.jpg";
import doughScience from "../../assets/images/dough-science.jpg";
import pizzaDoughHands from "../../assets/images/pizza-dough-hands.png";
import saltImage from "../../assets/images/salt.jpg";
import hydrationImage from "../../assets/images/hydration.jpg";
import proofingBalls from "../../assets/images/proofing-balls.jpg";
import chefDrawing from "../../assets/images/chef-drawing.png";
import gooderyMobileAd from "../../assets/images/mobile-ad-goodery.png";
import gooderyDesktoopAd from "../../assets/images/desktop-ad-goodery.png";
import timWithPizza from "../../assets/images/tim-with-pizza.jpeg";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";

export default function Home() {
  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    document.title = "Library of Pizza - Machines Learning Pizza";
    window.scrollTo({ top: 0 });
  }, []);

  const getStartedButton = () => {
    if (user) {
      return (
        <Link to="/my-pizzas/add">
          <button className="button">GET STARTED</button>
        </Link>
      );
    } else {
      return (
        <Link to="/login">
          <button className="button">GET STARTED</button>
        </Link>
      );
    }
  };

  const exportRecipeCard = () => {
    htmlToImage
      .toPng(document.querySelector(".pizza__recipe-card"))
      .then(function (dataUrl) {
        download(dataUrl, "recipecard_libraryofpizza.png");
      });
  };

  return (
    <div className="content">
      <div className="content__page-heading">
        {/* <img
          src={chefDrawing}
          alt="chef kneading dough"
          className="content__chef-icon"
        /> */}
        {/* <p className="content__page-heading-emojis">🤖🍕</p> */}
        <h1 className="content__page-heading-title">The Library of Pizza</h1>
        <p className="content__page-heading-tag">
          Where machine learning meets pizza making!
        </p>
      </div>
      <div className="content__block">
        <div className="content__block-inner-left order-1">
          <h2 className="content__title">Why machines make good pizza</h2>
          <p className="content__copy">
            If you’re serious about making great pizza, you have at some point
            have had a notebook with pizzas you’ve made, or formulas you’ve
            tried. All your successes and failures in one fragile place.
          </p>
        </div>
        <div className="content__block-inner-right order-2">
          <img src={doughScience} alt="chef kneading dough" />
        </div>
      </div>
      <div className="content__block">
        <div className="content__block-inner-left order-2">
          <img src={doughKneading} alt="roaring flame in a brick oven" />
        </div>
        <div className="content__block-inner-right order-1">
          <h2 className="content__title">For the experimenter</h2>
          <p className="content__copy">
            This library is a collection of all of our notebooks, in one place.
            With the power of machine learning, we take all of that data and put
            it into one ultimate formula, perfecting our craft!
          </p>
        </div>
      </div>
      <div className="content__block-center">
        <Loading />
        <h2 className="content__title--center">Meet P.A.O.L.A</h2>
        <p className="content__copy--center">
          The A.I. that helps us find the next pizza you're going to cook and,
          one day, your ultimate pizza formula!
        </p>
        <p className="content__copy--center">
          When the machines inevitably rise up, I feel I'll have done my part in
          making the technological subjugation bearable, with delicious, perfect
          pizza.
        </p>
      </div>
      <div className="content__block">
        <div className="content__block-inner-left order-1">
          <h2 className="content__title">How to use The Library</h2>{" "}
          <p className="content__copy">
            Each time you write a pizza into the library, and give it your
            ratings, your suggested formula will change. Each time you add
            another pizza, the accuracy of your ultimate formula gets closer.
          </p>
        </div>
        <div className="content__block-inner-right order-2">
          <img src={pizzaDoughHands} alt="chef kneading dough" />
        </div>
      </div>
      <div className="content__block">
        <div className="content__block-inner-left order-2">
          <img src={pizzaBase} alt="chef holding his dough" />
        </div>
        <div className="content__block-inner-right order-1">
          <h2 className="content__title">The steps</h2>
          <ul className="content__copy--list">
            <li>1. make some pizza dough</li>
            <li>
              2. load the formula to the library{" "}
              <Link to="/my-pizzas/add">here</Link>
            </li>
            <li>3. make the pizza</li>
            <li>4. eat the pizza</li>
            <li className="indent">
              (don't forget to do the closed eyes crust in the air wave to show
              everyone how much you love your own pizza)
            </li>
            <li>5. rate the pizza in the library</li>
            <li>
              6. receive your new formula <Link to="/my-formula">here</Link>
            </li>
            <li>7. repeat</li>
          </ul>
        </div>
      </div>
      <div className="content__block">
        <div className="content__block-inner-left order-1">
          <h2 className="content__title">Resources for beginners</h2>{" "}
          <p className="content__copy">
            If you haven't made pizza before, or are just starting out, I
            recommend heading over to the method page, and reading on below to
            learn a bit more about making pizzas from scatch.
          </p>
          <p className="content__copy">I promise you will never look back!</p>
          <Link to="/method">
            <button className="button">The Method</button>
          </Link>
        </div>
        <div className="content__block-inner-right order-2">
          <img src={margherita} alt="chef kneading dough" />
        </div>
      </div>
      <img
        src={gooderyDesktoopAd}
        alt="goodery veg delivery ad"
        className="content__goodery-ad-desktop"
      />
      <img
        src={gooderyMobileAd}
        alt="goodery veg delivery ad"
        className="content__goodery-ad-mobile"
      />
      <h2 className="content__title">Where to start</h2>
      <p className="content__copy">
        If you haven't made pizza before, here is a formula that is both tasty
        and an excellent starting point on your own personal pizza journey:
      </p>
      <RecipeCard
        hydration="67"
        salt="2.8"
        cptime="48"
        cptemp="4"
        rtptime="5"
        rtptemp="24"
        ballWeight="240"
        yeast="1"
      />
      <button className="button--full" onClick={exportRecipeCard}>
        download recipe card
      </button>
      <p className="content__copy--center">
        Here is a brief description of how each of these inputs:
      </p>
      <div className="content__block">
        <div className="content__block-inner-left order-1">
          <h3 className="content__sub-title">Hydration</h3>
          <p className="content__copy">
            This is the amount of water compared to flour. We use bakers
            percentage for this which is explained below.
          </p>
          <p className="content__copy">
            Fun fact: as soon as the hydration is 100% or above it's no longer a
            dough, but a batter!
          </p>
        </div>
        <div className="content__block-inner-left order-2">
          <img src={hydrationImage} alt="clay jug" />
        </div>
      </div>
      <div className="content__block">
        <div className="content__block-inner-left order-2">
          <img src={saltImage} alt="crystal salt on a wooden spoon" />
        </div>
        <div className="content__block-inner-right order-1">
          <h3 className="content__sub-title">Salt</h3>
          <p className="content__copy">
            Salt is what holds the pizza together (well it helps at least) and
            makes it mmmm. This also uses bakers percentage.
          </p>
          <p className="content__copy">
            Fun fact: the dough you see in pizza tossing competitions would
            taste foul because it is so salty to keep it from breaking.
          </p>
        </div>
      </div>
      <div className="content__block">
        <div className="content__block-inner-left order-1">
          <h3 className="content__sub-title">Cold Proofing Time</h3>
          <p className="content__copy">
            This is the amount of time the dough spends in the fridge when
            proofing.
          </p>
          <p className="content__copy">
            Fun fact: cooling the dough down in the fridge helps slow down the
            production of gasses by the yeast, which in turn slows the rise of
            the dough itself. Neat huh?
          </p>
          <h3 className="content__sub-title">Cold Proofing Temp</h3>
          <p className="content__copy">This is how cold your fridge is.</p>
          <p className="content__copy">
            Fun fact: I bet you have no idea how cold your fridge is.
          </p>
          <h3 className="content__sub-title">Warm Proofing Time</h3>
          <p className="content__copy">
            This includes the time you spend bulk proofing (before breaking it
            into individual balls) and the time just before you cook the pizza
            after taking it out of the fridge.
          </p>
          <p className="content__copy">
            Fun fact: this is the opposite of cold proofing time.{" "}
          </p>
          <h3 className="content__sub-title">Warm Proofing Temp</h3>
          <p className="content__copy">
            This is how cold your kitchen (assuming you make dough in the
            kitchen) is. This is also how cold your bathroom is if you're a mad
            person.
          </p>
          <p className="content__copy">
            Fun fact: it is a universal truth that you cannot actually control
            the temperature in the kitchen, it just be what it be.
          </p>
        </div>

        <div className="content__block-inner-right order-2">
          <img src={proofingBalls} alt="dough balls proofing" />
        </div>
      </div>
      <div className="content__block">
        <div className="content__block-inner-left order-2">
          <img src={cookedPizza} alt="half a pizza" />
        </div>
        <div className="content__block-inner-right order-1">
          <h3 className="content__sub-title">Individual ball weight </h3>
          <p className="content__copy">This is the size of your balls.</p>
          <p className="content__copy">
            Fun fact: I mean dough balls after you separate them to cold prove.
          </p>
          <h3 className="content__sub-title">Yeast</h3>
          <p className="content__copy">
            This is all important, and the type of yeast you use really does
            matter. The Al Gore Rhythm is yeast agnostic and assumes you always
            use the same type of yeast. This also uses bakers percentage.
          </p>
          <p className="content__copy">
            Fun fact: there are so many types of yeast, each with a different
            purpose: Active dry, Fresh (cake), Liquid, Instant, Nutritional,
            Yeast Extract, Ale yeast, lager yeast, and Kanyeast.
          </p>
        </div>
      </div>
      <h2 className="content__title">what is baker's percentage?</h2>{" "}
      <p className="content__copy">
        All the values that are measured in % are using bakers percentage, if
        you don't know what that is, it is the amount of the ingredient relative
        to the amount of flour.
      </p>
      <p className="content__copy">
        For example, If I want to make dough with 100g of flour, and I want to
        use a 65% hydration, we need 65g of water.
      </p>
      <p className="content__copy">Simple.</p>
      <h2 className="content__title">
        What are you waiting for fellow Librarian?
      </h2>{" "}
      {getStartedButton()}
      <img
        src={timWithPizza}
        alt="tim with pizza"
        className="content__final-pic"
      />
      {/* <img src={logo} alt="logo" className="content__logo" /> */}
    </div>
  );
}
