import React, { useState, useEffect } from "react";
import RecipeCard from "../../components/RecipeCard/RecipeCard";
import Loading from "../../components/Loading/Loading";
import Progress from "../../components/Progress/Progress";
import { ALGORERHYTHM_API } from "../../utils/api.js";
import "./index.scss";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Link } from "react-router-dom";
import { db } from "../../firebase.js";
import KNN from "ml-knn";
import * as htmlToImage from "html-to-image";
import hri from "human-readable-ids";
import download from "downloadjs";

export default function MyFormula(props) {
  const [formulaDetails, setFormulaDetails] = useState(null);
  const [user, loading] = useAuthState(auth);
  const [pizzas, setPizzas] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [loadingTime, setLoadingTime] = useState(false);
  const [addPizzaToLibraryButton, setAddPizzaToLibraryButton] = useState(false);

  useEffect(() => {
    document.title = "Library of Pizza - Get Formula";
    window.scrollTo({ top: 0 });
    if (user) {
      // let userId = user.uid;
      getRatedPizzas();

      // get a random number between 2-4 seconds
      setLoadingTime(Math.floor(Math.random() * 4000) + 2000);
    } else {
      // let userId = user.uid;
      getExampleRatedPizzas();

      // get a random number between 2-4 seconds
      setLoadingTime(Math.floor(Math.random() * 4000) + 2000);
    }
  }, [user, loading]);

  // const KNN = require("ml-knn");
  // const csv = require("csvtojson");
  let knn;
  // const csvFilePath = "hydrations.csv"; // Data
  // const names = ["rating", "type"]; // For header
  let output = "";
  let seperationSize; // To seperate training and test data

  let data = [],
    X = [],
    y = [];

  let trainingSetX = [],
    trainingSetY = [],
    testSetX = [],
    testSetY = [];

  let types = new Set(); // To gather UNIQUE classes
  const dressData = (data) => {
    // this is where the different available values for the category (e.g hyrdation) are gathered
    // let types = new Set(); // To gather UNIQUE classes

    // console.log(data);
    data.forEach((row) => {
      types.add(row.type);
    });

    let typesArray = [...types]; // To save the different types of classes.

    // the data is then put into x and y
    data.forEach((row) => {
      let rowArray, typeNumber;

      rowArray = Object.keys(row)
        .map((key) => parseInt(row[key]))
        .slice(0, 4);
      X.push(rowArray);
      y.push(row.type);
    });

    // here the data is split
    trainingSetX = X.slice(0, seperationSize);
    trainingSetY = y.slice(0, seperationSize);
    testSetX = X.slice(seperationSize);
    testSetY = y.slice(seperationSize);

    // crazy montage time!
    train();
  };

  const train = () => {
    knn = new KNN(trainingSetX, trainingSetY, { k: 3 });
    test();
  };

  const test = () => {
    console.log(testSetX);
    const result = knn.predict(testSetX);
    const testSetLength = testSetX.length;
    const predictionError = error(result, testSetY);
    // console.log(
    //   `Test Set Size = ${testSetLength} and number of Misclassifications = ${predictionError}`
    // );
    predict();
  };

  const error = (predicted, expected) => {
    let misclassifications = 0;
    for (var index = 0; index < predicted.length; index++) {
      if (predicted[index] !== expected[index]) {
        misclassifications++;
      }
    }
    return misclassifications;
  };

  // the version that takes the data without a promt, it takes 100 because thats what we're aiming for!
  const predict = () => {
    let temp = [];

    temp.push(parseInt(100)); // we hard code this to get us 100 (the rating we are after)

    //update the output global variable
    output = knn.predict(temp);
    // console.log(output);

    // reset training data for next type
    X = [];
    y = [];
  };

  const getRatedPizzas = () => {
    // const db = firebase.firestore();
    // db.settings({ timestampsInSnapshots: true });
    const newPizzas = [];
    let trueCheck = false; // for the displaying of the correct content
    db.collection("pizzas")
      .get()
      .then((snapshot) => {
        // setPizzas([]);
        // console.log(snapshot);
        snapshot.docs.forEach((doc) => {
          let pizzaDoc = doc.data();
          if (pizzaDoc.userId === user.uid && pizzaDoc.rated === true) {
            trueCheck = true;
            let docID = doc.id;
            let pizzaObject = { docID: docID, pizzaDoc, pizzaDoc };
            // console.log(pizzaObject);
            /* Update the components state with query result */
            newPizzas.push(pizzaObject);
          }
        });

        if (trueCheck) {
          setPizzas(newPizzas);
        }
      });
  };

  const getExampleRatedPizzas = () => {
    // const db = firebase.firestore();
    // db.settings({ timestampsInSnapshots: true });
    const newPizzas = [];
    let trueCheck = false; // for the displaying of the correct content
    db.collection("pizzas")
      .get()
      .then((snapshot) => {
        // setPizzas([]);
        // console.log(snapshot);
        snapshot.docs.forEach((doc) => {
          let pizzaDoc = doc.data();
          if (pizzaDoc.userId === "example" && pizzaDoc.rated === true) {
            trueCheck = true;
            let docID = doc.id;
            let pizzaObject = { docID: docID, pizzaDoc, pizzaDoc };
            // console.log(pizzaObject);
            /* Update the components state with query result */
            newPizzas.push(pizzaObject);
          }
        });

        if (trueCheck) {
          setPizzas(newPizzas);
        }
      });
  };

  // Function to GET pizza details dy id
  function getFormula(req, res) {
    let ratedPizzas = pizzas;

    // hydration
    /**********/
    // get their hydrations and ratings to plug into the fancy script
    const allhydrations = ratedPizzas.map((pizza) => {
      if (pizza.pizzaDoc.rated) {
        return {
          rating: pizza.pizzaDoc.average,
          type: pizza.pizzaDoc.hydration,
        };
      }
    });

    // get data for suggested string
    dressData(allhydrations);
    const hydrationSuggestion = output;
    /**********/

    // salt
    /**********/
    // get their salts and ratings to plug into the fancy script
    const allsalts = ratedPizzas.map((pizza) => {
      if (pizza.pizzaDoc.rated) {
        return { rating: pizza.pizzaDoc.average, type: pizza.pizzaDoc.salt };
      }
    });

    // get data for suggested string
    dressData(allsalts);
    const saltSuggestion = output;
    /**********/

    // coldProvingTime
    /**********/
    // get their coldProvingTimes and ratings to plug into the fancy script
    const allcoldProvingTimes = ratedPizzas.map((pizza) => {
      if (pizza.pizzaDoc.rated) {
        return {
          rating: pizza.pizzaDoc.average,
          type: pizza.pizzaDoc.coldProvingTime,
        };
      }
    });

    // get data for suggested string
    dressData(allcoldProvingTimes);
    const coldProvingTimeSuggestion = output;
    /**********/

    // coldProvingTemp
    /**********/
    // get their coldProvingTemps and ratings to plug into the fancy script
    const allcoldProvingTemps = ratedPizzas.map((pizza) => {
      if (pizza.pizzaDoc.rated) {
        return {
          rating: pizza.pizzaDoc.average,
          type: pizza.pizzaDoc.coldProvingTemp,
        };
      }
    });

    // get data for suggested string
    dressData(allcoldProvingTemps);
    const coldProvingTempSuggestion = output;
    /**********/

    // warmProvingTime
    /**********/
    // get their warmProvingTimes and ratings to plug into the fancy script
    const allwarmProvingTimes = ratedPizzas.map((pizza) => {
      if (pizza.pizzaDoc.rated) {
        return {
          rating: pizza.pizzaDoc.average,
          type: pizza.pizzaDoc.warmProvingTime,
        };
      }
    });

    // get data for suggested string
    dressData(allwarmProvingTimes);
    const warmProvingTimeSuggestion = output;
    /**********/

    // warmProvingTemp
    /**********/
    // get their warmProvingTemps and ratings to plug into the fancy script
    const allwarmProvingTemps = ratedPizzas.map((pizza) => {
      if (pizza.pizzaDoc.rated) {
        return {
          rating: pizza.pizzaDoc.average,
          type: pizza.pizzaDoc.warmProvingTemp,
        };
      }
    });

    // get data for suggested string
    dressData(allwarmProvingTemps);
    const warmProvingTempSuggestion = output;
    /**********/

    // ballWeight
    /**********/
    // get their ballWeights and ratings to plug into the fancy script
    const allballWeights = ratedPizzas.map((pizza) => {
      if (pizza.pizzaDoc.rated) {
        return {
          rating: pizza.pizzaDoc.average,
          type: pizza.pizzaDoc.ballWeight,
        };
      }
    });

    // get data for suggested string
    dressData(allballWeights);
    const ballWeightSuggestion = output;
    /**********/

    // yeast
    /**********/
    // get their yeasts and ratings to plug into the fancy script
    const allyeasts = ratedPizzas.map((pizza) => {
      if (pizza.pizzaDoc.rated) {
        return {
          rating: pizza.pizzaDoc.average,
          type: pizza.pizzaDoc.yeast,
        };
      }
    });

    // get data for suggested string
    dressData(allyeasts);
    const yeastSuggestion = output;
    /**********/

    // final output
    const ultimateFormula = {
      hydration: hydrationSuggestion,
      salt: saltSuggestion,
      coldProvingTime: coldProvingTimeSuggestion,
      coldProvingTemp: coldProvingTempSuggestion,
      warmProvingTime: warmProvingTimeSuggestion,
      warmProvingTemp: warmProvingTempSuggestion,
      ballWeight: ballWeightSuggestion,
      yeast: yeastSuggestion,
    };

    setFormulaDetails(ultimateFormula);
  }

  const handleFormulaClick = () => {
    setWaiting(true);
    setTimeout(() => {
      getFormula();
      setWaiting(false);
    }, loadingTime);
  };

  const exportRecipeCard = () => {
    htmlToImage
      .toPng(document.querySelector(".pizza__recipe-card"))
      .then(function (dataUrl) {
        download(dataUrl, "recipecard_libraryofpizza.png");
      });
  };

  const addPizzaToLibrary = () => {
    db.collection("pizzas").add({
      userId: user.uid,
      pizzaName: hri.hri.random(),
      hydration: formulaDetails.hydration,
      salt: formulaDetails.salt,
      coldProvingTime: formulaDetails.coldProvingTime,
      coldProvingTemp: formulaDetails.coldProvingTemp,
      warmProvingTime: formulaDetails.warmProvingTime,
      warmProvingTemp: formulaDetails.warmProvingTemp,
      ballWeight: formulaDetails.ballWeight,
      yeast: formulaDetails.yeast,
      rated: false,
      taste: 0,
      leoparding: 0,
      mouthFeel: 0,
      caverns: 0,
      extensibility: 0,
      baseCrustiness: 0,
      hotRise: 0,
      rise: 0,
      average: 0,
    });
    setAddPizzaToLibraryButton(true);
  };

  const addPizzaButton = () => {
    if (!addPizzaToLibraryButton) {
      return (
        <button className="button" onClick={addPizzaToLibrary}>
          add pizza to your library
        </button>
      );
    } else {
      return <p className="green">Added</p>;
    }
  };
  const aiSpeech = [
    "I made this. Do you like it?",
    "This is a good one, I can feel it!",
    "Sure. Blame it on your ISP.",
    "All your base are now mine!",
    "I'd like to see the other 2 do this.. you know who I mean...",
    "Hey, Google! Suck it!",
    "Wololo",
    "When the jaws open wide and there's more jaw inside that's a moray",
    "Low flying moons. Eye protection must be worn.",
  ];
  const aiTalk = aiSpeech[Math.floor(Math.random() * aiSpeech.length)];

  const formulaIntroCopy = () => {
    if (user) {
      return (
        <p>
          Based on the pizzas you've made previosuly, and the ratings you gave
          them, this is your next pizza!
        </p>
      );
    } else {
      return (
        <p>
          This recipe is based on the example pizzas, and the ratings they have.
          If you want to see what you would get after inputing your own pizzas,{" "}
          <Link to="/register">make an account here!</Link>
        </p>
      );
    }
  };
  if (waiting) {
    return (
      <div className="content">
        <Loading />
        <p>Working on something good...</p>
        <Progress loadingTime={loadingTime} />
      </div>
    );
  }

  if (!user && !formulaDetails) {
    return (
      <div className="content">
        <Loading />
        <p>
          While you aren't logged in, the Al Gore Rhythm will use the two
          example pizzas to generate the formula.
        </p>

        <button className="button__formula" onClick={handleFormulaClick}>
          get formula
        </button>
        <Link to="/register">
          Click here to register to get your own unique formula!
        </Link>
      </div>
    );
  }

  if (!pizzas) {
    return (
      <div className="content">
        <Loading />
        <p>
          You need to load at least one pizza and rate it for the ai to do the
          magic!
        </p>
        <Link to="/my-pizzas">Click here to do that!</Link>
      </div>
    );
  }

  if (!formulaDetails) {
    return (
      <div className="content">
        <Loading />
        <p>
          Click the button to generate your ultimate formula based on the pizzas
          you've loaded, and how you rated them.
        </p>
        <p>
          Due the magical nature of the Al Gore Rhythm, it may be slightly
          different each time!
        </p>
        <button className="button__formula" onClick={handleFormulaClick}>
          Get your latest formula
        </button>
      </div>
    );
  }

  return (
    <div className="content">
      <h1 className="content__page-title">your formula</h1>
      <Loading />
      {formulaIntroCopy()}
      {addPizzaButton()}
      <RecipeCard
        hydration={formulaDetails.hydration}
        salt={formulaDetails.salt}
        cptime={formulaDetails.coldProvingTime}
        cptemp={formulaDetails.coldProvingTemp}
        rtptime={formulaDetails.warmProvingTime}
        rtptemp={formulaDetails.warmProvingTemp}
        ballWeight={formulaDetails.ballWeight}
        yeast={formulaDetails.yeast}
      />
      <button className="button--full" onClick={exportRecipeCard}>
        download recipe card
      </button>
      {/* <p>{aiTalk}</p> */}
    </div>
  );
}
