import React from "react";

export default function index() {
  return (
    <div className="content">
      <h1 className="content__page-title">thanks</h1>
      <p className="content__copy">
        This project wouldn't have been possible without the epic help of so
        many people. This is page is an inferior token of my gratitude to get
        the library where it is!
      </p>
      <h2 className="content__sub-title">bootcamp educators</h2>
      <p className="content__copy">
        Thank you so much to Noor, Joe and Benga, for getting me through the
        bootcamp!
      </p>
      <p className="content__copy">
        An extra special thanks to Joe for helping me with the machine learning
        part of this project!
      </p>
      <h2 className="content__sub-title">friends & family</h2>
      <p className="content__copy">
        Thanks to mama, papa, Josh, David, Tracey & Barbara for getting this
        site's spelling up to snuff! James and Caleb for mega ideas, including
        the flour information and the radar charts! Thanks to John for the idea
        to do a project with machine learning. And finally thank you for my ever
        patient and supportive wife Bekah!
      </p>
      <p className="content__copy">
        An extra specfial thanks to my genius cousin Samuel who helped me get
        the yeast suggestion formula to a point where it can mathematically be
        used in a function. Gosh he's so clever.
      </p>
    </div>
  );
}
