import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import { PIZZA_LIST_API } from "../../utils/api";
import EditPizzaForm from "../../components/EditPizzaForm/EditPizzaForm";
import { db } from "../../firebase.js";

export default function EditPizza(props) {
  const [formValid, setFormValid] = useState(true);

  const [docID, setDocID] = useState("");

  const [form, setForm] = useState({
    pizzaId: "",
    userId: "",
    hydration: "",
    salt: "",
    coldProvingTime: "",
    coldProvingTemp: "",
    warmProvingTime: "",
    warmProvingTemp: "",
    ballWeight: "",
    yeast: "",
    rated: "",
    taste: "",
    leoparding: "",
    mouthFeel: "",
    caverns: "",
    extensibility: "",
    baseCrustiness: "",
    hotRise: "",
    rise: "",
    average: "",
  });
  // set state while the user types
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const getPizza = (pizzaId) => {
    db.collection("pizzas")
      .doc(pizzaId)
      .get()
      .then((docRef) => {
        let docID = docRef.id;
        setDocID(docID);

        let pizzaDoc = docRef.data();
        document.title = `Library of Pizza - Edit ${pizzaDoc.pizzaName}`;
        const formData = {
          pizzaId: pizzaDoc.pizzaId,
          userId: pizzaDoc.userId,
          hydration: pizzaDoc.hydration,
          salt: pizzaDoc.salt,
          coldProvingTime: pizzaDoc.coldProvingTime,
          coldProvingTemp: pizzaDoc.coldProvingTemp,
          warmProvingTime: pizzaDoc.warmProvingTime,
          warmProvingTemp: pizzaDoc.warmProvingTemp,
          ballWeight: pizzaDoc.ballWeight,
          yeast: pizzaDoc.yeast,
          rated: pizzaDoc.rated,
          taste: pizzaDoc.taste,
          leoparding: pizzaDoc.leoparding,
          mouthFeel: pizzaDoc.mouthFeel,
          caverns: pizzaDoc.caverns,
          extensibility: pizzaDoc.extensibility,
          baseCrustiness: pizzaDoc.baseCrustiness,
          hotRise: pizzaDoc.hotRise,
          rise: pizzaDoc.rise,
          average: pizzaDoc.hydration,
        };
        setForm(formData);
      });
  };
  // const getPizza = (pizzaId) => {
  //   db.collection("pizzas")
  //     .get()
  //     .then((snapshot) => {
  //       console.log(snapshot.docs);
  //       snapshot.docs.forEach((doc) => {
  //         let pizzaDoc = doc.data();
  //         if (pizzaDoc.pizzaId == pizzaId) {
  //           // set form data
  //           let docID = doc.id;
  //           setDocID(docID);

  //           const formData = {
  //             pizzaId: pizzaDoc.pizzaId,
  //             userId: pizzaDoc.userId,
  //             hydration: pizzaDoc.hydration,
  //             salt: pizzaDoc.salt,
  //             coldProvingTime: pizzaDoc.coldProvingTime,
  //             coldProvingTemp: pizzaDoc.coldProvingTemp,
  //             warmProvingTime: pizzaDoc.warmProvingTime,
  //             warmProvingTemp: pizzaDoc.warmProvingTemp,
  //             ballWeight: pizzaDoc.ballWeight,
  //             yeast: pizzaDoc.yeast,
  //             rated: pizzaDoc.rated,
  //             taste: pizzaDoc.taste,
  //             leoparding: pizzaDoc.leoparding,
  //             mouthFeel: pizzaDoc.mouthFeel,
  //             caverns: pizzaDoc.caverns,
  //             extensibility: pizzaDoc.extensibility,
  //             baseCrustiness: pizzaDoc.baseCrustiness,
  //             hotRise: pizzaDoc.hotRise,
  //             rise: pizzaDoc.rise,
  //             average: pizzaDoc.hydration,
  //           };
  //           setForm(formData);
  //           console.log(formData);
  //         }
  //       });
  //     });
  // };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    let pizzaId = props.match.params.id;
    getPizza(pizzaId);
    // getPizzaDetails(pizzaId);
  }, [props.match.params.id]);

  // const getPizzaDetails = (pizzaId) => {
  //   axios
  //     .get(`${PIZZA_LIST_API}/${pizzaId}`)
  //     .then((response) => {
  //       const formData = {
  //         pizzaId: response.data.formula.pizzaId,
  //         userId: response.data.formula.userId,
  //         hydration: response.data.formula.hydration,
  //         salt: response.data.formula.salt,
  //         coldProvingTime: response.data.formula.coldProvingTime,
  //         coldProvingTemp: response.data.formula.coldProvingTemp,
  //         warmProvingTime: response.data.formula.warmProvingTime,
  //         warmProvingTemp: response.data.formula.warmProvingTemp,
  //         ballWeight: response.data.formula.ballWeight,
  //         yeast: response.data.formula.yeast,
  //         rated: response.data.formula.rated,
  //         taste: response.data.rating.taste,
  //         leoparding: response.data.rating.leoparding,
  //         mouthFeel: response.data.rating.mouthFeel,
  //         caverns: response.data.rating.caverns,
  //         extensibility: response.data.rating.extensibility,
  //         baseCrustiness: response.data.rating.baseCrustiness,
  //         hotRise: response.data.rating.hotRise,
  //         rise: response.data.rating.rise,
  //         average: response.data.formula.hydration,
  //       };
  //       setForm(formData);
  //     })
  //     .then(() => console.log(form))
  //     .catch((err) =>
  //       console.log("Something went wrong while fetching the pizza data: ", err)
  //     );
  // };

  const isFormValid = () => {
    let formValid = true;

    let hydrationCheck = document.querySelector(".hydration-check");
    let saltCheck = document.querySelector(".salt-check");
    let coldProvingTimeCheck = document.querySelector(
      ".cold-proving-time-check"
    );
    let coldProvingTempCheck = document.querySelector(
      ".cold-proving-temp-check"
    );
    let warmProvingTimeCheck = document.querySelector(
      ".warm-proving-time-check"
    );
    let warmProvingTempCheck = document.querySelector(
      ".warm-proving-temp-check"
    );
    let ballWeightCheck = document.querySelector(".ball-weight-check");
    let yeastCheck = document.querySelector(".yeast-check");
    let tasteCheck = document.querySelector(".taste-check");
    let leopardingCheck = document.querySelector(".leoparding-check");
    let mouthFeelCheck = document.querySelector(".mouth-feel-check");
    let cavernsCheck = document.querySelector(".caverns-check");
    let extensibilityCheck = document.querySelector(".extensibility-check");
    let baseCrustinessCheck = document.querySelector(".base-crustiness-check");
    let hotRiseCheck = document.querySelector(".hot-rise-check");
    let riseCheck = document.querySelector(".rise-check");

    if (!form.hydration) {
      hydrationCheck.classList.add("show");
      formValid = false;
    } else {
      hydrationCheck.classList.remove("show");
    }

    if (!form.salt) {
      saltCheck.classList.add("show");
      formValid = false;
    } else {
      saltCheck.classList.remove("show");
    }

    if (!form.coldProvingTime) {
      coldProvingTimeCheck.classList.add("show");
      formValid = false;
    } else {
      coldProvingTimeCheck.classList.remove("show");
    }
    if (!form.coldProvingTemp) {
      coldProvingTempCheck.classList.add("show");
      formValid = false;
    } else {
      coldProvingTempCheck.classList.remove("show");
    }
    if (!form.warmProvingTime) {
      warmProvingTimeCheck.classList.add("show");
      formValid = false;
    } else {
      warmProvingTimeCheck.classList.remove("show");
    }
    if (!form.warmProvingTemp) {
      warmProvingTempCheck.classList.add("show");
      formValid = false;
    } else {
      warmProvingTempCheck.classList.remove("show");
    }
    if (!form.ballWeight) {
      ballWeightCheck.classList.add("show");
      formValid = false;
    } else {
      ballWeightCheck.classList.remove("show");
    }
    if (!form.yeast) {
      yeastCheck.classList.add("show");
      formValid = false;
    } else {
      yeastCheck.classList.remove("show");
    }
    if (!form.taste) {
      tasteCheck.classList.add("show");
      formValid = false;
    } else {
      tasteCheck.classList.remove("show");
    }

    if (!form.leoparding) {
      leopardingCheck.classList.add("show");
      formValid = false;
    } else {
      leopardingCheck.classList.remove("show");
    }

    if (!form.mouthFeel) {
      mouthFeelCheck.classList.add("show");
      formValid = false;
    } else {
      mouthFeelCheck.classList.remove("show");
    }
    if (!form.caverns) {
      cavernsCheck.classList.add("show");
      formValid = false;
    } else {
      cavernsCheck.classList.remove("show");
    }
    if (!form.extensibility) {
      extensibilityCheck.classList.add("show");
      formValid = false;
    } else {
      extensibilityCheck.classList.remove("show");
    }
    if (!form.baseCrustiness) {
      baseCrustinessCheck.classList.add("show");
      formValid = false;
    } else {
      baseCrustinessCheck.classList.remove("show");
    }
    if (!form.hotRise) {
      hotRiseCheck.classList.add("show");
      formValid = false;
    } else {
      hotRiseCheck.classList.remove("show");
    }
    if (!form.rise) {
      riseCheck.classList.add("show");
      formValid = false;
    } else {
      riseCheck.classList.remove("show");
    }
    console.log("need to add ratingds to the validation!");
    //make db call
    if (formValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let hydration = form.hydration;
    let salt = form.salt;
    let coldProvingTime = form.coldProvingTime;
    let coldProvingTemp = form.coldProvingTemp;
    let warmProvingTime = form.warmProvingTime;
    let warmProvingTemp = form.warmProvingTemp;
    let ballWeight = form.ballWeight;
    let yeast = form.yeast;
    let taste = form.taste;
    let leoparding = form.leoparding;
    let mouthFeel = form.mouthFeel;
    let caverns = form.caverns;
    let extensibility = form.extensibility;
    let baseCrustiness = form.baseCrustiness;
    let hotRise = form.hotRise;
    let rise = form.rise;

    let average =
      (parseFloat(taste) +
        parseFloat(leoparding) +
        parseFloat(mouthFeel) +
        parseFloat(caverns) +
        parseFloat(extensibility) +
        parseFloat(baseCrustiness) +
        parseFloat(hotRise) +
        parseFloat(rise)) /
      8;

    if (isFormValid()) {
      setFormValid(true);

      // const editedPizza = {
      //   userId: 1,
      //   hydration,
      //   salt,
      //   coldProvingTime,
      //   coldProvingTemp,
      //   warmProvingTime,
      //   warmProvingTemp,
      //   ballWeight,
      //   yeast,
      //   taste,
      //   leoparding,
      //   mouthFeel,
      //   caverns,
      //   extensibility,
      //   baseCrustiness,
      //   hotRise,
      //   rise,
      //   average,
      // };

      // axios
      //   .put(`${PIZZA_LIST_API}/`, editedPizza)
      //   .then(() => console.log(editedPizza))
      //   .catch();
      db.collection("pizzas")
        .doc(docID)
        .update({
          hydration,
          salt,
          coldProvingTime,
          coldProvingTemp,
          warmProvingTime,
          warmProvingTemp,
          ballWeight,
          yeast,
          taste,
          leoparding,
          mouthFeel,
          caverns,
          extensibility,
          baseCrustiness,
          hotRise,
          rise,
          average,
        })
        .then(() => {
          // redirect after submit
          const url = "/my-pizzas/" + docID;
          window.location.replace(url);
        });
    } else {
      setFormValid(false);
    }
  };

  if (!form) {
    return (
      <div className="content">
        <Loading />
      </div>
    );
  }
  return (
    <div className="content">
      <h1 className="content__page-title">edit pizza</h1>
      <EditPizzaForm
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        formDefaults={form}
      />
    </div>
  );
}
