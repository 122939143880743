import React, { useEffect, useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
// import Loading from "../../components/Loading/Loading";
import PizzaListItem from "../../components/PizzaListItem/PizzaListItem";
import { PIZZA_LIST_API } from "../../utils/api";
import axios from "axios";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import Loading from "../../components/Loading/Loading";
import { db } from "../../firebase.js";

export default function MyPizzas() {
  const [user, loading, error] = useAuthState(auth);
  const [pizzaList, setPizzaList] = useState([]);
  const history = useHistory();

  const [pizzas, setPizzas] = useState([]);

  const getPizzas = () => {
    // const db = firebase.firestore();
    // db.settings({ timestampsInSnapshots: true });
    const newPizzas = [];
    db.collection("pizzas")
      .get()
      .then((snapshot) => {
        // setPizzas([]);
        console.log(snapshot);
        snapshot.docs.forEach((doc) => {
          let pizzaDoc = doc.data();
          if (pizzaDoc.userId === user.uid) {
            let docID = doc.id;
            let pizzaObject = { docID: docID, pizzaDoc, pizzaDoc };
            console.log(pizzaObject);
            /* Update the components state with query result */
            newPizzas.push(pizzaObject);
          }
        });
        setPizzas(newPizzas);
      });
  };
  const getExamplePizzas = () => {
    // const db = firebase.firestore();
    // db.settings({ timestampsInSnapshots: true });
    const newPizzas = [];
    db.collection("pizzas")
      .get()
      .then((snapshot) => {
        // setPizzas([]);
        console.log(snapshot);
        snapshot.docs.forEach((doc) => {
          let pizzaDoc = doc.data();
          if (pizzaDoc.userId === "example") {
            let docID = doc.id;
            let pizzaObject = { docID: docID, pizzaDoc, pizzaDoc };
            console.log(pizzaObject);
            /* Update the components state with query result */
            newPizzas.push(pizzaObject);
          }
        });
        setPizzas(newPizzas);
      });
  };
  useEffect(() => {
    document.title = "Library of Pizza - My Pizzas";
    window.scrollTo({ top: 0 });
    // if (loading) return;
    // if (!user) return history.replace("/login");
    if (user) {
      // getPizzaList();

      getPizzas();
    } else {
      getExamplePizzas();
    }
  }, [user]);

  // const getPizzaList = () => {
  //   axios
  //     .get(`${PIZZA_LIST_API}/list/${user.uid}`)
  //     .then((response) => {
  //       setPizzaList(response.data);
  //       console.log(response.data);
  //     })
  //     .catch((err) =>
  //       console.log("Something went wrong while fetching the pizza data: ", err)
  //     );
  // };

  // console.log(pizzaList);
  if (!user) {
    return (
      <div className="content">
        <h1 className="content__page-title">example pizzas</h1>
        <p className="content__copy">
          Here is an example list of all the pizzas you could make, click into
          them to see how each pizza is made, and how they are rated. All the
          information here is what drives the algorithm, giving you your next
          pizza!
        </p>
        <p className="content__copy">
          While you aren't logged in,{" "}
          <Link to="/my-formula">
            these are the pizzas used for the algorithm here
          </Link>
          . When you log in, you can create your own and get your unique formula
          made for you!
        </p>

        <p className="add-pizza__comingsoon">
          you can add your own pizzas when you log in
        </p>
        <button className="button__disabled">+ add pizza</button>

        <Link to="/register" className="content__space-below">
          Click here to register so you can make your own pizzas!
        </Link>
        {pizzas.map((pizza) => {
          return (
            <PizzaListItem
              key={pizza.pizzaDoc.pizzaId}
              pizzaId={pizza.docID}
              pizzaName={pizza.pizzaDoc.pizzaName}
              taste={pizza.pizzaDoc.taste}
              leoparding={pizza.pizzaDoc.leoparding}
              mouthFeel={pizza.pizzaDoc.mouthFeel}
              caverns={pizza.pizzaDoc.caverns}
              extensibility={pizza.pizzaDoc.extensibility}
              baseCrustiness={pizza.pizzaDoc.baseCrustiness}
              hotRise={pizza.pizzaDoc.hotRise}
              rise={pizza.pizzaDoc.rise}
              average={pizza.pizzaDoc.average}
            />
          );
        })}

        {/* {pizzaList.map((pizza) => {
        return (
          <PizzaListItem
            key={pizza.pizzaId}
            pizzaId={pizza.pizzaId}
            rating={pizza.rating}
          />
        );
      })} */}
      </div>
    );
  }
  // if (!pizzaList) {
  //   return (
  //     <div className="content">
  //       <Loading />
  //       <p>cooking something up...</p>
  //     </div>
  //   );
  // }

  return (
    <div className="content">
      <h1 className="content__title">your pizzas</h1>
      <p className="content__copy">
        Here is a list of all the pizzas you've made, click into them to see how
        you made them, and how they are rated. All the information here is what
        drives the algorithm, giving you your next pizza!
      </p>
      <p className="content__copy">
        Each pizza is given a unique name. Here at the library, we like to think
        the AI is smart enough to know something about each pizza that we didn't
        see, and that the name is a good descriptor of the pizza itself!
      </p>
      <Link to="/my-pizzas/add">
        <button className="button">+ add pizza</button>
      </Link>

      {pizzas.map((pizza) => {
        return (
          <PizzaListItem
            key={pizza.pizzaDoc.pizzaId}
            pizzaId={pizza.docID}
            pizzaName={pizza.pizzaDoc.pizzaName}
            taste={pizza.pizzaDoc.taste}
            leoparding={pizza.pizzaDoc.leoparding}
            mouthFeel={pizza.pizzaDoc.mouthFeel}
            caverns={pizza.pizzaDoc.caverns}
            extensibility={pizza.pizzaDoc.extensibility}
            baseCrustiness={pizza.pizzaDoc.baseCrustiness}
            hotRise={pizza.pizzaDoc.hotRise}
            rise={pizza.pizzaDoc.rise}
            average={pizza.pizzaDoc.average}
          />
        );
      })}

      {/* {pizzaList.map((pizza) => {
        return (
          <PizzaListItem
            key={pizza.pizzaId}
            pizzaId={pizza.pizzaId}
            rating={pizza.rating}
          />
        );
      })} */}
    </div>
  );
}
