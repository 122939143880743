import axios from "axios";
import React, { useEffect, useState } from "react";
import AddPizzaForm from "../../components/AddPizzaForm/AddPizzaForm";
import { PIZZA_LIST_API } from "../../utils/api";
import { db } from "../../firebase.js";
import uniqid from "uniqid";
import hri from "human-readable-ids";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";

export default function AddPizza() {
  const [formValid, setFormValid] = useState(true);
  const [user, loading, error] = useAuthState(auth);

  const [form, setForm] = useState({
    hydration: "",
    salt: "",
    coldProvingTime: "",
    coldProvingTemp: "",
    warmProvingTime: "",
    warmProvingTemp: "",
    ballWeight: "",
    yeast: "",
  });

  useEffect(() => {
    document.title = "Library of Pizza - Add Pizza";
    // getPizzaDetails(pizzaId);
  }, []);

  // set state while the user types
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const suggestYeast = (e) => {
    e.preventDefault();
    //     console.log("test");
    //     const eulersNum = 2.71828;
    //     // formula here
    //     let ac = 0.26;
    // let RTLY = 0.1026958 + 0.6188767 * eulersNum**(−0.1761209 * warmProvingTime);
    // // N.B. Limits -> 1 <= RTL <= 18
    // let RTCY = 0.6132143 + 0.0119483 * warmProvingTemp − 0.002936515 * warmProvingTemp**2 + 0.00008882828 * warmProvingTemp**3 − 0.0000008060606 * warmProvingTemp**4;
    // // N.B. Limits -> 10 <= RTC <= 40
    // let CTLY = 0.647972 − 0.01820901 * coldProvingTime + 0.0003175991 * coldProvingTime**2 − 0.000002921523 * coldProvingTime**3 + 1. 048951𝑒 − 8 * coldProvingTime**4;
    // // N.B. Limits -> 0 <= CTL <= 100
    // let CTCY = 0.3926932 − 0.0212077 * coldProvingTemp − 0.0006120855 * coldProvingTemp**2 + 0.00006687722 * coldProvingTemp**3 − 0. 000001346665 * coldProvingTemp**4;
    // N.B. Limits - 1 <= CTC <= 20

    const yeastSuggest = 0;
    // set yeast field

    setForm({ ...form, yeast: yeastSuggest });
  };

  const isFormValid = () => {
    let formValid = true;

    let hydrationCheck = document.querySelector(".hydration-check");
    let saltCheck = document.querySelector(".salt-check");
    let coldProvingTimeCheck = document.querySelector(
      ".cold-proving-time-check"
    );
    let coldProvingTempCheck = document.querySelector(
      ".cold-proving-temp-check"
    );
    let warmProvingTimeCheck = document.querySelector(
      ".warm-proving-time-check"
    );
    let warmProvingTempCheck = document.querySelector(
      ".warm-proving-temp-check"
    );
    let ballWeightCheck = document.querySelector(".ball-weight-check");
    let yeastCheck = document.querySelector(".yeast-check");

    if (!form.hydration) {
      hydrationCheck.classList.add("show");
      formValid = false;
    } else {
      hydrationCheck.classList.remove("show");
    }

    if (!form.salt) {
      saltCheck.classList.add("show");
      formValid = false;
    } else {
      saltCheck.classList.remove("show");
    }

    if (!form.coldProvingTime) {
      coldProvingTimeCheck.classList.add("show");
      formValid = false;
    } else {
      coldProvingTimeCheck.classList.remove("show");
    }
    if (!form.coldProvingTemp) {
      coldProvingTempCheck.classList.add("show");
      formValid = false;
    } else {
      coldProvingTempCheck.classList.remove("show");
    }
    if (!form.warmProvingTime) {
      warmProvingTimeCheck.classList.add("show");
      formValid = false;
    } else {
      warmProvingTimeCheck.classList.remove("show");
    }
    if (!form.warmProvingTemp) {
      warmProvingTempCheck.classList.add("show");
      formValid = false;
    } else {
      warmProvingTempCheck.classList.remove("show");
    }
    if (!form.ballWeight) {
      ballWeightCheck.classList.add("show");
      formValid = false;
    } else {
      ballWeightCheck.classList.remove("show");
    }
    if (!form.yeast) {
      yeastCheck.classList.add("show");
      formValid = false;
    } else {
      yeastCheck.classList.remove("show");
    }

    //make axios call
    if (formValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let hydration = form.hydration;
    let salt = form.salt;
    let coldProvingTime = form.coldProvingTime;
    let coldProvingTemp = form.coldProvingTemp;
    let warmProvingTime = form.warmProvingTime;
    let warmProvingTemp = form.warmProvingTemp;
    let ballWeight = form.ballWeight;
    let yeast = form.yeast;

    if (isFormValid()) {
      setFormValid(true);

      // const newPizza = {
      //   userId: 1,
      //   hydration,
      //   salt,
      //   coldProvingTime,
      //   coldProvingTemp,
      //   warmProvingTime,
      //   warmProvingTemp,
      //   ballWeight,
      //   yeast,
      // };

      // axios
      //   .post(`${PIZZA_LIST_API}/`, newPizza)
      //   .then(() => console.log(newPizza))
      //   .catch();

      db.collection("pizzas")
        .add({
          userId: user.uid,
          pizzaName: hri.hri.random(),
          hydration,
          salt,
          coldProvingTime,
          coldProvingTemp,
          warmProvingTime,
          warmProvingTemp,
          ballWeight,
          yeast,
          rated: false,
          taste: 0,
          leoparding: 0,
          mouthFeel: 0,
          caverns: 0,
          extensibility: 0,
          baseCrustiness: 0,
          hotRise: 0,
          rise: 0,
          average: 0,
        })
        .then((docRef) => {
          // redirect after submit
          const url = `/my-pizzas/${docRef.id}`;
          window.location.replace(url);
        });
    } else {
      setFormValid(false);
    }
  };

  return (
    <div className="content">
      <h1 className="content__page-title">new pizza</h1>
      <AddPizzaForm
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        suggestYeast={suggestYeast}
      />
    </div>
  );
}
