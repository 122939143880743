import React from "react";
import "./AddPizzaForm.scss";

export default function AddPizzaForm({
  handleSubmit,
  handleChange,
  suggestYeast,
}) {
  return (
    <form className="add-pizza" onSubmit={handleSubmit}>
      <div className="add-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="add-pizza__input"
          name="hydration"
          placeholder="hydration"
          onChange={handleChange}
        ></input>
        <span className="add-pizza__unit">%</span>
        <p className="add-pizza__form-error-text hydration-check">
          This field is required
        </p>
      </div>
      <div className="add-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="add-pizza__input"
          name="salt"
          placeholder="salt"
          onChange={handleChange}
        ></input>
        <span className="add-pizza__unit">%</span>
        <p className="add-pizza__form-error-text salt-check">
          This field is required
        </p>
      </div>
      <div className="add-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="add-pizza__input"
          name="coldProvingTime"
          placeholder="cold proofing time"
          onChange={handleChange}
        ></input>
        <span className="add-pizza__unit">hrs</span>
        <p className="add-pizza__form-error-text cold-proving-time-check">
          This field is required
        </p>
      </div>
      <div className="add-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="add-pizza__input"
          name="coldProvingTemp"
          placeholder="cold proofing temp"
          onChange={handleChange}
        ></input>
        <span className="add-pizza__unit">&deg;C</span>
        <p className="add-pizza__form-error-text cold-proving-temp-check">
          This field is required
        </p>
      </div>
      <div className="add-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="add-pizza__input"
          name="warmProvingTime"
          placeholder="warm proofing time"
          onChange={handleChange}
        ></input>
        <span className="add-pizza__unit">hrs</span>
        <p className="add-pizza__form-error-text warm-proving-time-check">
          This field is required
        </p>
      </div>
      <div className="add-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="add-pizza__input"
          name="warmProvingTemp"
          placeholder="warm proofing temp"
          onChange={handleChange}
        ></input>
        <span className="add-pizza__unit">&deg;C</span>
        <p className="add-pizza__form-error-text warm-proving-temp-check">
          This field is required
        </p>
      </div>
      <div className="add-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="add-pizza__input"
          name="ballWeight"
          placeholder="ball weight"
          onChange={handleChange}
        ></input>
        <span className="add-pizza__unit">g</span>
        <p className="add-pizza__form-error-text ball-weight-check">
          This field is required
        </p>
      </div>
      <div className="add-pizza__button-wrap">
        <p className="add-pizza__comingsoon">this feature is coming soon!</p>
        <button className="button__disabled" onClick={suggestYeast}>
          Suggest yeast for me
        </button>
      </div>
      <div className="add-pizza__input-wrap">
        <input
          type="number"
          step=".01"
          className="add-pizza__input"
          name="yeast"
          placeholder="yeast"
          onChange={handleChange}
        ></input>
        <span className="add-pizza__unit">%</span>
        <p className="add-pizza__form-error-text yeast-check">
          This field is required
        </p>
      </div>
      <button className="button" type="submit">
        + Add Pizza
      </button>
    </form>
  );
}
