import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import RecipeCard from "../../components/RecipeCard/RecipeCard";

export default function PizzaTools() {
  useEffect(() => {
    document.title = "Library of Pizza - Method";
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div className="content">
      <h1 className="content__page-title">method</h1>
      <p className="content__copy">
        I'm going to upset a lot of people with this write up, but such is the
        way with passionate folk!
      </p>
      <p className="content__copy">
        This is not an exhaustive method, nor is it the only/best way. It's the
        way I've come to do it after 4 years of honing.
      </p>

      <p className="content__copy">For the specific ingredients:</p>
      <ul className="content__copy--list-black">
        <li className="highlight">- Tesco own Spring water</li>
        <li className="indent">
          Not chilled, yeast will die. Incidentally I've found this water is
          also great for bringing more flavour out in your pour over coffee.
        </li>
        <li className="indent">
          The reason I use bottled water is hard water is bad for yeast (yet
          again yeast being a big baby), unless you are blessed enough live in
          Scotland, bottle water &gt; dead yeast.
        </li>
        <li className="highlight">- Caputo active dry yeast.</li>
        <li className="indent">Nothing special, just yeast in a tin.</li>
        <li className="highlight">- Caputo 00 gauge flour</li>
        <li className="indent">
          If you're using a pizza oven its gotta be 00 guage or no gauge
        </li>
        <li className="highlight">- Table salt</li>
        <li className="indent">
          The finer it is, the less work you'll have later on
        </li>
      </ul>
      <RecipeCard
        hydration="67"
        salt="2.8"
        cptime="48"
        cptemp="4"
        rtptime="5"
        rtptemp="24"
        ballWeight="240"
        yeast="1"
      />
      <p className="content__copy">
        This method uses an autolyse step, so you don't have to do as much
        kneading.
      </p>
      <p className="content__copy--border-top">
        So for the autolyse, mix the flour and the water together and make sure
        as much of it is combined as possible. If you are using active dry yeast
        it needs activating in water, so when you mix the water and flour, save
        30-60 grams of water for later.
      </p>
      <p className="content__copy--border-top">
        We are then going to leave this, covered, for 1-24 hours (you read that
        correctly, I've yet to do it for 24 hours, but if you want a super
        complex flavour profile, try it out!
      </p>
      <p className="content__copy--border-top">
        When you're ready, flatten the mixture out of the surface as much as you
        can and sprinkle the salt evenly over it.
      </p>
      <p className="content__copy--border-top">
        Then you're going to poke the salt INTO the dough, and then knead it so
        all the salt is incorporated.
      </p>
      <p className="content__copy--border-top">
        Then flatten it out, and add the yeast. If you're using active dry, this
        is when you will use the rest of the water. Mix the water and yeast
        first, let it sit for a bit, then mix it in.
      </p>

      <p className="content__copy--border-top">
        Now knead away, but instead of kneading for 10 minutes, we only need
        knead for a minute or two thanks to the autolyse.
      </p>

      <p className="content__copy--border-top">
        A tip for kneading, fold it like it's a paper folding competition, and
        then drop it like it's hot, over and over again.
      </p>
      <p className="content__copy--border-top">
        Cover the giant ball of delicious dough in a damp cloth for 30 minutes
        while you desperately try to get to ick out from between your fingers!
      </p>
      <p className="content__copy--border-top">
        Then roll the dough into a more cylindrical shape, just so it's easier
        to cut into equal sizes, and cut into 4 230g balls. I weigh them, you
        don't have to.
      </p>
      <p className="content__copy--border-top">
        Then do the weird pizza thing where you roll them into balls.{" "}
        <a href="https://youtu.be/v5t5MEZt6LM">
          Gonna link to someone who is more word good than me's method:
        </a>
      </p>
      <p className="content__copy--border-top">
        Leave those balls in the fridge covered, I have one of{" "}
        <a href="https://amzn.to/3pMPhuU" target="_blank">
          these proofing boxes
        </a>
        , and I like to put 8/10/12 in each.
      </p>
      <p className="content__copy--border-top">
        This wouldn't be a complete writeup if I didn't say how I made it into
        pizza shape, but again,{" "}
        <a href="https://youtu.be/9f9-xTcKzZo">I no word good.</a>
        (but don't throw your poor pizza dough like he does at the end please)
      </p>
      <p className="content__copy--border-top">
        If you don't know where to get any of the tools or ingredients I
        mentioned, check out this page!
      </p>

      <Link to={`/pizza-tools`}>
        <button className="button">pizza tools</button>
      </Link>
    </div>
  );
}
