import React, { useState } from "react";
import NavBar from "../NavBar/NavBar";
import "./PageHeader.scss";
import { Link } from "react-router-dom";
import { logout, auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import chefDrawing from "../../assets/images/chef-drawing.png";

// mobile menu
import { slide as Burger, SubMenu } from "burger-menu";
import "burger-menu/lib/index.css";

import { Squash as Hamburger } from "hamburger-react";

export default function PageHeader() {
  const [user, loading, error] = useAuthState(auth);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };
  const userBar = () => {
    if (!user) {
      return (
        <div className="header__user-links">
          <Link
            to="/login"
            className="header__user-links-item header__nav-link"
          >
            login
          </Link>
          {" / / "}
          <Link
            to="/register"
            className="header__user-links-item header__nav-link"
          >
            register
          </Link>
        </div>
      );
    } else {
      return (
        <div className="header__user-links">
          <Link
            to="/account"
            className="header__user-links-item header__nav-link"
          >
            account
          </Link>
        </div>
      );
    }
  };

  return (
    <header className="header">
      <div className="header__desktop-user">{userBar(user)}</div>

      <Link to="/" className="header__logo-wrap">
        <img
          src={chefDrawing}
          alt="chef-drawing"
          className="header__chef-logo"
        />
        <h1 className="header__text-logo">
          libraryofpizza<span className="blink">_</span>
        </h1>
      </Link>

      <div onClick={() => setIsOpen(!isOpen)}>
        <Hamburger toggled={isOpen} toggle={setIsOpen} label="Show menu" />
      </div>
      <Burger
        className="header__mobile-nav"
        isOpen={isOpen}
        onClose={handleClose}
        width="55%"
      >
        <div className="header__mobile-user">{userBar(user)}</div>
        <NavBar />
      </Burger>
      <div className="header__desktop-nav">
        <NavBar />
      </div>
    </header>
  );
}
