import React from "react";
import "./NavBar.scss";
import { Link, NavLink } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";

export default function NavBar() {
  const [user, loading, error] = useAuthState(auth);
  // otherwise home is always "active"
  const checkActive = (match, location) => {
    //some additional logic to verify you are in the home URI
    if (!location) return false;
    const { pathname } = location;
    return pathname === "/";
  };

  const addPizzaButton = () => {
    if (user) {
      return (
        <Link to="/my-pizzas/add">
          <button className="button">ADD A PIZZA</button>
        </Link>
      );
    } else {
      return (
        <Link to="/login">
          <button className="button">ADD A PIZZA</button>
        </Link>
      );
    }
  };
  return (
    <ul className="header__nav">
      <li className="header__nav-item">
        <NavLink to="/" className="header__nav-link" isActive={checkActive}>
          home
        </NavLink>
      </li>
      <li className="header__nav-item">
        <NavLink to="/my-pizzas" className="header__nav-link">
          your pizzas
        </NavLink>
      </li>
      <li className="header__nav-item">
        <NavLink to="/my-formula" className="header__nav-link">
          get formula
        </NavLink>
      </li>
      <li className="header__nav-item">
        <NavLink to="/pizza-tools" className="header__nav-link">
          tools
        </NavLink>
      </li>
      <li className="header__nav-item">
        <NavLink to="/method" className="header__nav-link">
          method
        </NavLink>
      </li>
      <li className="header__nav-item">{addPizzaButton()}</li>
    </ul>
  );
}
