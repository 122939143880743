import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  auth,
  signInWithEmailAndPassword,
  signInWithGoogle,
} from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.scss";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    document.title = "Library of Pizza - Login";
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) history.replace("/account");
  }, [user, loading]);
  return (
    <div className="content">
      <h1 className="content__page-title">Login</h1>
      <p className="content_copy">
        Login to create pizzas and get the sweet sweet formula from the AI!
      </p>
      <div className="login">
        <div className="login__container">
          <input
            type="text"
            className="login__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
          <input
            type="password"
            className="login__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button
            className="button"
            onClick={() => signInWithEmailAndPassword(email, password)}
          >
            login
          </button>
          <button className="button button__google" onClick={signInWithGoogle}>
            login with Google
          </button>
          <div>
            <Link to="/reset">Forgot Password</Link>
          </div>
          <div>
            Don't have an account? <Link to="/register">Register</Link> now.
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
