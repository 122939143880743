import React from "react";
import RadarChart from "../RadarChart/RadarChart";
import "./PizzaRatings.scss";

export default function PizzaRatings({
  taste,
  leoparding,
  mouthFeel,
  caverns,
  extensibility,
  baseCrustiness,
  hotRise,
  rise,
  average,
}) {
  const data = {
    name: "Pizza #1",
    captions: {
      // columns
      taste: "Taste",
      leoparding: "Leoparding",
      mouthFeel: "Mouth feel",
      caverns: "Caverns",
      extensibility: "Extensibility",
      baseCrustiness: "Base Crustiness",
      hotRise: "Hot Rise",
      rise: "Rise",
    },
    chart: [
      // data
      {
        data: {
          taste: parseFloat(taste) / 100,
          leoparding: parseFloat(leoparding) / 100,
          mouthFeel: parseFloat(mouthFeel) / 100,
          caverns: parseFloat(caverns) / 100,
          extensibility: parseFloat(extensibility) / 100,
          baseCrustiness: parseFloat(baseCrustiness) / 100,
          hotRise: parseFloat(hotRise) / 100,
          rise: parseFloat(rise) / 100,
        },
        meta: { color: "#f6416c" },
      },
    ],
    size: 600,
    options: {
      className: "caption",
      textAnchor: "middle",
      fontSize: 16,
    },
  };

  return (
    <section className="pizza__ratings">
      <h3 className="pizza__ratings-title">Ratings</h3>
      <p className="pizza__ratings-content">
        The end game is to get that sauce spread evenly on the dough!
      </p>
      <div className="pizza__radar-chart">
        <RadarChart
          captions={data.captions}
          data={data.chart}
          size={data.size}
          options={data.options}
        />
      </div>
      <div className="pizza__ratings-group">
        <label className="pizza__ratings-label">Taste</label>
        <label className="pizza__ratings-item">{taste}</label>
      </div>
      <div className="pizza__ratings-group">
        <label className="pizza__ratings-label">Leoparding</label>
        <label className="pizza__ratings-item">{leoparding}</label>
      </div>
      <div className="pizza__ratings-group">
        <label className="pizza__ratings-label">Mouth feel</label>
        <label className="pizza__ratings-item">{mouthFeel}</label>
      </div>
      <div className="pizza__ratings-group">
        <label className="pizza__ratings-label">Caverns</label>
        <label className="pizza__ratings-item">{caverns}</label>
      </div>
      <div className="pizza__ratings-group">
        <label className="pizza__ratings-label">Extensibility</label>
        <label className="pizza__ratings-item">{extensibility}</label>
      </div>
      <div className="pizza__ratings-group">
        <label className="pizza__ratings-label">Base crustiness</label>
        <label className="pizza__ratings-item">{baseCrustiness}</label>
      </div>
      <div className="pizza__ratings-group">
        <label className="pizza__ratings-label">Hot Rise</label>
        <label className="pizza__ratings-item">{hotRise}</label>
      </div>
      <div className="pizza__ratings-group">
        <label className="pizza__ratings-label">Rise</label>
        <label className="pizza__ratings-item">{rise}</label>
      </div>
      <div className="pizza__ratings-group">
        <label className="pizza__ratings-label average">Pizza Score</label>
        <label className="pizza__ratings-item">{average}</label>
      </div>
    </section>
  );
}
