import React from "react";
import "./Loading.scss";

export default function Loading() {
  return (
    <div className="wrap">
      <span className="one"></span>
      <span className="two"></span>
      <span className="three"></span>
      <span className="four"></span>
      <span className="five"></span>
      <span className="six"></span>
      <span className="seven"></span>
      <span className="eight"></span>
      <span className="center"></span>
    </div>
  );
}
