import React from "react";
import { Link } from "react-router-dom";
import "./EditPizzaForm.scss";

export default function EditPizzaForm({
  handleSubmit,
  handleChange,
  formDefaults,
}) {
  console.log(formDefaults);
  return (
    <>
      <form className="edit-pizza" onSubmit={handleSubmit}>
        <h2 className="content__title">pizza ratings</h2>
        <p className="content__copy--highlight">
          Only change these details if you input them incorrectly. If you have
          made a new pizza, add a new one <Link to="/my-pizzas/add">here</Link>{" "}
          instead.
        </p>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">taste</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="taste"
            placeholder="taste"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.taste}
          />
          <span className="edit-pizza__unit">/100</span>
          <p className="edit-pizza__form-error-text taste-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">leoparding</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="leoparding"
            placeholder="leoparding"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.leoparding}
          />
          <span className="edit-pizza__unit">/100</span>
          <p className="edit-pizza__form-error-text leoparding-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">mouth feel</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="mouthFeel"
            placeholder="mouth feel"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.mouthFeel}
          />
          <span className="edit-pizza__unit">/100</span>
          <p className="edit-pizza__form-error-text mouth-feel-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">caverns</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="caverns"
            placeholder="caverns"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.caverns}
          />
          <span className="edit-pizza__unit">/100</span>
          <p className="edit-pizza__form-error-text caverns-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">extensibility</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="extensibility"
            placeholder="extensibility"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.extensibility}
          />
          <span className="edit-pizza__unit">/100</span>
          <p className="edit-pizza__form-error-text extensibility-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">baseCrustiness</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="baseCrustiness"
            placeholder="base crustiness"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.baseCrustiness}
          />
          <span className="edit-pizza__unit">/100</span>
          <p className="edit-pizza__form-error-text base-crustiness-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">hot rise</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="hotRise"
            placeholder="hot rise"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.hotRise}
          />
          <span className="edit-pizza__unit">/100</span>
          <p className="edit-pizza__form-error-text hot-rise-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">rise</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="rise"
            placeholder="rise"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.rise}
          ></input>
          <span className="edit-pizza__unit">/100</span>
          <p className="edit-pizza__form-error-text rise-check">
            This field is required
          </p>
        </div>
        <h2 className="content__title">pizza details</h2>
        <p className="content__copy--highlight">
          Only change these details if you input them incorrectly. If you have
          made a new pizza, add a new one <Link to="/my-pizzas/add">here</Link>{" "}
          instead.
        </p>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">hydration</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="hydration"
            placeholder="hydration"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.hydration}
          ></input>
          <span className="edit-pizza__unit">%</span>
          <p className="edit-pizza__form-error-text hydration-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">salt</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="salt"
            placeholder="salt"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.salt}
          ></input>
          <span className="edit-pizza__unit">%</span>
          <p className="edit-pizza__form-error-text salt-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">cold proofing time</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="coldProvingTime"
            placeholder="cold proofing time"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.coldProvingTime}
          ></input>
          <span className="edit-pizza__unit">hrs</span>
          <p className="edit-pizza__form-error-text cold-proving-time-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">cold proofing temp</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="coldProvingTemp"
            placeholder="cold proofing temp"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.coldProvingTemp}
          ></input>
          <span className="edit-pizza__unit">&deg;C</span>
          <p className="edit-pizza__form-error-text cold-proving-temp-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">warm proofing time</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="warmProvingTime"
            placeholder="warm proofing time"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.warmProvingTime}
          ></input>
          <span className="edit-pizza__unit">hrs</span>
          <p className="edit-pizza__form-error-text warm-proving-time-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">warm proofing temp</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="warmProvingTemp"
            placeholder="warm proofing temp"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.warmProvingTemp}
          ></input>
          <span className="edit-pizza__unit">&deg;C</span>
          <p className="edit-pizza__form-error-text warm-proving-temp-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">ball weight</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="ballWeight"
            placeholder="ball weight"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.ballWeight}
          ></input>
          <span className="edit-pizza__unit">g</span>
          <p className="edit-pizza__form-error-text ball-weight-check">
            This field is required
          </p>
        </div>
        <div className="edit-pizza__input-wrap">
          <label className="edit-pizza__label">yeast</label>
          <input
            type="number"
            step=".01"
            className="edit-pizza__input"
            name="yeast"
            placeholder="yeast"
            autocomplete="off"
            onChange={handleChange}
            value={formDefaults.yeast}
          ></input>
          <span className="edit-pizza__unit">%</span>
          <p className="edit-pizza__form-error-text yeast-check">
            This field is required
          </p>
        </div>
        <button className="button" type="submit">
          submit
        </button>
      </form>
    </>
  );
}
