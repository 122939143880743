import React from "react";
import "./PageFooter.scss";
import { Link } from "react-router-dom";
import instagramIcon from "../../assets/icons/instagram-icon.png";
import githubIcon from "../../assets/icons/github-icon.png";
export default function PageFooter() {
  return (
    <footer className="footer">
      <section className="footer__section">
        <h3 className="footer__heading">Pizza knowledge</h3>
        <ul className="footer__site-map">
          <li className="footer__nav-item">
            <Link to="/method" className="footer__link">
              my method
            </Link>
          </li>
          <li className="footer__nav-item">
            <Link to="/pizza-tools" className="footer__link">
              my tools
            </Link>
          </li>
          <li className="footer__nav-item">
            <a
              href="mailto:loddonpizzasmiths@gmail.com"
              className="footer__link"
            >
              my email
            </a>
          </li>
        </ul>
      </section>
      <section className="footer__section">
        <h3 className="footer__heading">Give me some dough</h3>

        <ul className="footer__site-map">
          <li className="footer__nav-item">
            <a
              href="https://www.paypal.me/wollivan"
              className="footer__link"
              target="_blank"
            >
              PayPal &#8599;
            </a>
          </li>
          <li className="footer__nav-item">
            <a
              href="https://buymeacoffee.com/libraryofpizza"
              className="footer__link"
              target="_blank"
            >
              buymeacoffee &#8599;
            </a>
          </li>
        </ul>
      </section>

      <section className="footer__section">
        <h3 className="footer__heading">Why not charity?</h3>
        <p className="footer__content">
          <a href="https://standby.me" className="footer__link" target="_blank">
            standby.me &#8599;
          </a>
        </p>
      </section>
      {/* <section className="footer__section">
        <h3 className="footer__heading">Social</h3>
        <a href="instagram.com" className="footer__link" target="_blank">
          <img
            src={instagramIcon}
            alt="instagram icon"
            className="footer__social-icon"
          />
        </a>
      </section> */}
    </footer>
  );
}
