import { Component } from "react";
import "./styles/App.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PageHeader from "./components/PageHeader/PageHeader";
import PageFooter from "./components/PageFooter/PageFooter";
import Home from "./pages/Home/index";
import MyPizzas from "./pages/MyPizzas/index";
import MyFormula from "./pages/MyFormula/index";
import Method from "./pages/Method/index";
import PizzaTools from "./pages/PizzaTools/index";
import Thanks from "./pages/Thanks/index";
import PizzaDetails from "./pages/MyPizzas/PizzaDetails";
import AddPizza from "./pages/MyPizzas/AddPizza";
import RatePizza from "./pages/MyPizzas/RatePizza";
import EditPizza from "./pages/MyPizzas/EditPizza";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Account from "./components/Account/Account";
import Reset from "./components/Reset/Reset";
import CookieConsent from "react-cookie-consent";

// import firebase from "firebase/compat/app";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <PageHeader />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/my-pizzas" exact component={MyPizzas} />
            <Route path="/my-pizzas/add" exact component={AddPizza} />
            <Route
              path="/my-pizzas/:id/"
              exact
              render={(routerProps) => {
                return <PizzaDetails {...routerProps} />;
              }}
            />
            <Route
              path="/my-pizzas/:id/rate"
              exact
              render={(routerProps) => {
                return <RatePizza {...routerProps} />;
              }}
            />
            <Route
              path="/my-pizzas/:id/edit"
              exact
              render={(routerProps) => {
                return <EditPizza {...routerProps} />;
              }}
            />
            <Route path="/method" exact component={Method} />
            <Route path="/pizza-tools" exact component={PizzaTools} />
            <Route path="/my-formula/" exact component={MyFormula} />
            <Route path="/thanks/" exact component={Thanks} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/reset" component={Reset} />
            <Route exact path="/account" component={Account} />
          </Switch>
          <PageFooter />
          <CookieConsent
            location="bottom"
            buttonText="No problem!"
            style={{
              background: "#f6416c",
              paddingBottom: "0.5rem",
              maxWidth: "100%",
            }}
            buttonStyle={{
              color: "#ffde7d",
              color: "#000",
              fontSize: "13px",
              borderRadius: "5px",
              fontFamily: "Roboto Mono,monospace",
            }}
            expires={150}
          >
            This website uses cookies to enhance the user experience.{" "}
            <span style={{ fontSize: "10px" }}>We use pizzas to!</span>
          </CookieConsent>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
