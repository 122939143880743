import React, { useEffect } from "react";
import proofingBoxImage from "../../assets/images/vivo-proofing-box.webp";
import pizzaPeelImage from "../../assets/images/homefavor-pizza-peel.webp";
import turningPizzaPeelImage from "../../assets/images/chef-pomodoro-turning-peel.webp";
import spatulaImage from "../../assets/images/cabilock-spatula.jpg";
import ooniKaruImage from "../../assets/images/ooni-karu.png";
import caputo00FlourImage from "../../assets/images/caputo-00-flour.jpeg";
import mezzalunaImage from "../../assets/images/gustav-mezzaluna.webp";
import platesImage from "../../assets/images/weber-pizza-plate.webp";
import tomatoesImage from "../../assets/images/eat-wholesome-tomatoes.webp";
import yeastImage from "../../assets/images/caputo-yeast.webp";
import ToolItem from "../../components/ToolItem/ToolItem";

export default function PizzaTools() {
  useEffect(() => {
    document.title = "Library of Pizza - Tools";
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div className="content">
      <h1 className="content__page-title">tools</h1>
      <p className="content__copy">
        Here is a collection of the tools I use (and the ones I wish I had).
      </p>
      <p className="content__copy">
        Full disclosure, these are affiliate links so I get a kickback if you
        buy something from these links.
      </p>
      <section className="tools">
        <ToolItem
          link="https://amzn.to/3pMPhuU"
          title="vivo proofing box"
          img={proofingBoxImage}
          description={
            <div className="tools__item-description-wrap">
              <p className="tools__item-description">
                The proofing box is so ridiculously important! This is how to
                have a modicum of control over the conditions your pizza is in.
              </p>

              <p className="tools__item-description">
                Also, if looks cool, can fit a bunch of pizza balls in and they
                stack!
              </p>

              <p className="tools__item-description">
                Make sure to get one that fits in your fridge!
              </p>
            </div>
          }
        />
        <ToolItem
          link="https://amzn.to/3e0pbPD"
          title="homefavor pizza peel"
          img={pizzaPeelImage}
          description={
            <div className="tools__item-description-wrap">
              <p className="tools__item-description">
                I find a wooden pizza peel much easier to use that a metal one,
                but it's totally subjective.
              </p>

              <p className="tools__item-description">
                I will say that the metal one that comes with an Ooni oven is
                better for getting the pizza out the oven though!
              </p>

              <p className="tools__item-description">
                Just be sure to sand it a little, or even just hold it over a
                naked flame to get rid of the fibres, makes it less liekly to
                "grab" your pizza.
              </p>
            </div>
          }
        />
        <ToolItem
          link="https://amzn.to/3ESuigl"
          title="chef pomodor turning pizza peel"
          img={turningPizzaPeelImage}
          description={
            <div className="tools__item-description-wrap">
              <p className="tools__item-description">
                I don't have one of these, but they are awesome!
              </p>

              <p className="tools__item-description">
                Turning a pizza half way through the cook is imperative, but if
                your peel is too wide you may need to take it out the oven to do
                it. This lets you turn it on the spot.
              </p>
            </div>
          }
        />
        <ToolItem
          link="https://amzn.to/3pNh6mQ"
          title="spatula"
          img={spatulaImage}
          description={
            <div className="tools__item-description-wrap">
              <p className="tools__item-description">
                Getting the dough out the box, cutting the dough when making it
                into ball, and scraping dough off the counter. This thing is
                invaluble!
              </p>

              <p className="tools__item-description">
                When getting the dough out of the proofing box, I recomend
                getting the spatula and your hand wet. It makes the dough less
                tacky to your hand, game changer!
              </p>
            </div>
          }
        />
        <ToolItem
          link="https://amzn.to/3yiahNG"
          title="ooni karu"
          img={ooniKaruImage}
          description={
            <div className="tools__item-description-wrap">
              <p className="tools__item-description">
                This isn't the oven I have, this one is better, newer, and
                prettier.
              </p>

              <p className="tools__item-description">
                We have an Ooni from when they were called Uuni, and it's so
                good, I can't recommend this brand enough!
              </p>
            </div>
          }
        />
        <ToolItem
          link="https://amzn.to/3ymrtC0"
          title="caputo 00 flour"
          img={caputo00FlourImage}
          description={
            <div className="tools__item-description-wrap">
              <p className="tools__item-description">
                If you're cooking at a high temperature (as in not in a
                conventional oven) you MUST use 00 gauge flour or the dough will
                burn before the toppings are cooked.
              </p>

              <p className="tools__item-description">
                Conversely, if you're cooking in a kitchen oven, use strong
                flour, not 00, otherwise the dough won't cook before the
                toppings are burnt.
              </p>

              <p className="tools__item-description">
                The packaging used to be blue for this stuff, so if you ever
                hear it referred to as Caputo blue, that's why.
              </p>
            </div>
          }
        />
        <ToolItem
          link="https://amzn.to/3GEtBYq"
          title="eat wholesome san marzano tomatoes"
          img={tomatoesImage}
          description={
            <div className="tools__item-description-wrap">
              <p className="tools__item-description">
                It can't be called Neapolitan pizza if it doesn't use San
                Marzano tomatoes for the sauce.
              </p>

              <p className="tools__item-description">
                I'm not just trying to be a snob saying that, it's defined by a
                body that decides what is and isn't Neapolitan pizza.
              </p>

              <p className="tools__item-description">
                Get the tins, crush the tomatoes, voila! Sauce.
              </p>
            </div>
          }
        />
        <ToolItem
          link="https://amzn.to/3pPXgXX"
          title="caputo yeast"
          img={yeastImage}
          description={
            <div className="tools__item-description-wrap">
              <p className="tools__item-description">
                Yeast is yeast is yeast. But I am but a yeast novice, so I am
                probably wrong about that.
              </p>

              <p className="tools__item-description">
                Either way, this is the stuff I use, because those packets use a
                lot of packaging, and have WAY too much yeast in each sachet!
              </p>
            </div>
          }
        />
        <ToolItem
          link="https://amzn.to/3218px2"
          title="gustav mezaluna"
          img={mezzalunaImage}
          description={
            <div className="tools__item-description-wrap">
              <p className="tools__item-description">
                I only recently started using a giant double-handed blade to cut
                my pizza... it's so much fun!
              </p>

              <p className="tools__item-description">
                Storage is a little tricky, maybe not if you have a giant magnet
                on your wall?
              </p>
            </div>
          }
        />
        <ToolItem
          link="https://amzn.to/3IHTlFk"
          title="weber pizza plates"
          img={platesImage}
          description={
            <div className="tools__item-description-wrap">
              <p className="tools__item-description">
                Again something I only wish I had, but I know from using these
                at a restaurant they hold the pizza nicely while you cut them,
                especially if you're using a roller.
              </p>

              <p className="tools__item-description">
                Certainly not an essential item, but a great way to train
                yourself to cook pizzas of a consistent size.
              </p>
            </div>
          }
        />
      </section>
      <p className="content__copy">
        Well that's it for now! Please check back here as I will be adding new
        bits as and when I try them/want to buy them myself!
      </p>
    </div>
  );
}
