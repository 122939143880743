import React, { useEffect, useState } from "react";
import RatePizzaForm from "../../components/RatePizzaForm/RatePizzaForm";
import { db } from "../../firebase.js";

export default function RatePizza(props) {
  const [formValid, setFormValid] = useState(true);

  const [docID, setDocID] = useState("");

  const [form, setForm] = useState({
    taste: "",
    leoparding: "",
    mouthFeel: "",
    caverns: "",
    extensibility: "",
    baseCrustiness: "",
    hotRise: "",
    rise: "",
    average: "",
  });
  // set state while the user types
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const getPizza = (pizzaId) => {
    db.collection("pizzas")
      .doc(pizzaId)
      .get()
      .then((docRef) => {
        let docID = docRef.id;
        setDocID(docID);
      });
  };
  useEffect(() => {
    document.title = "Library of Pizza - Rate Pizza";
    let pizzaId = props.match.params.id;
    window.scrollTo({ top: 0 });
    getPizza(pizzaId);
  }, []);

  const isFormValid = () => {
    let formValid = true;

    let tasteCheck = document.querySelector(".taste-check");
    let leopardingCheck = document.querySelector(".leoparding-check");
    let mouthFeelCheck = document.querySelector(".mouth-feel-check");
    let cavernsCheck = document.querySelector(".caverns-check");
    let extensibilityCheck = document.querySelector(".extensibility-check");
    let baseCrustinessCheck = document.querySelector(".base-crustiness-check");
    let hotRiseCheck = document.querySelector(".hot-rise-check");
    let riseCheck = document.querySelector(".rise-check");

    if (!form.taste) {
      tasteCheck.classList.add("show");
      formValid = false;
    } else {
      tasteCheck.classList.remove("show");
    }

    if (!form.leoparding) {
      leopardingCheck.classList.add("show");
      formValid = false;
    } else {
      leopardingCheck.classList.remove("show");
    }

    if (!form.mouthFeel) {
      mouthFeelCheck.classList.add("show");
      formValid = false;
    } else {
      mouthFeelCheck.classList.remove("show");
    }
    if (!form.caverns) {
      cavernsCheck.classList.add("show");
      formValid = false;
    } else {
      cavernsCheck.classList.remove("show");
    }
    if (!form.extensibility) {
      extensibilityCheck.classList.add("show");
      formValid = false;
    } else {
      extensibilityCheck.classList.remove("show");
    }
    if (!form.baseCrustiness) {
      baseCrustinessCheck.classList.add("show");
      formValid = false;
    } else {
      baseCrustinessCheck.classList.remove("show");
    }
    if (!form.hotRise) {
      hotRiseCheck.classList.add("show");
      formValid = false;
    } else {
      hotRiseCheck.classList.remove("show");
    }
    if (!form.rise) {
      riseCheck.classList.add("show");
      formValid = false;
    } else {
      riseCheck.classList.remove("show");
    }

    //make db call
    if (formValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let taste = form.taste;
    let leoparding = form.leoparding;
    let mouthFeel = form.mouthFeel;
    let caverns = form.caverns;
    let extensibility = form.extensibility;
    let baseCrustiness = form.baseCrustiness;
    let hotRise = form.hotRise;
    let rise = form.rise;

    let average =
      (parseFloat(taste) +
        parseFloat(leoparding) +
        parseFloat(mouthFeel) +
        parseFloat(caverns) +
        parseFloat(extensibility) +
        parseFloat(baseCrustiness) +
        parseFloat(hotRise) +
        parseFloat(rise)) /
      8;

    if (isFormValid()) {
      setFormValid(true);

      db.collection("pizzas")
        .doc(docID)
        .update({
          rated: true,
          taste,
          leoparding,
          mouthFeel,
          caverns,
          extensibility,
          baseCrustiness,
          hotRise,
          rise,
          average,
        })
        .then(() => {
          // redirect after submit
          const url = "/my-pizzas/" + docID;
          window.location.replace(url);
        });
    } else {
      setFormValid(false);
    }
  };

  return (
    <div className="content">
      <h1>rate pizza</h1>
      <RatePizzaForm handleChange={handleChange} handleSubmit={handleSubmit} />
    </div>
  );
}
