import React from "react";
import "./Progress.scss";
import PizzaLoader from "../../assets/images/pizza-loader.png";
import WhiteSquare from "../../assets/images/white-square.png";

export default function Progress({ loadingTime }) {
  // create the 60 part animation
  let keyframes = "@keyframes tickround {";
  for (let i = 0; i <= 60; i++) {
    keyframes +=
      (i * 100) / 60 +
      "% {-webkit-mask-image: conic-gradient(transparent " +
      6 * i +
      "deg " +
      6 * (i + 1) +
      "deg, black " +
      6 * (i + 1) +
      "deg 360deg); mask-image: conic-gradient(transparent " +
      6 * i +
      "deg " +
      6 * (i + 1) +
      "deg, black " +
      6 * (i + 1) +
      "deg 360deg);}";
  }
  keyframes += "}";
  const newstyle = document.createElement("style");
  newstyle.innerHTML = keyframes;
  document.head.appendChild(newstyle);

  const style = {
    animation: `tickround ${loadingTime / 1000}s ease-in infinite`,
  };
  return (
    <div class="progress">
      <img src={PizzaLoader} />
      <img class="progress__cover" src={WhiteSquare} style={style} />
    </div>
  );
}
