import React from "react";
import "./RecipeCard.scss";

export default function RecipeCard({
  hydration,
  salt,
  cptime,
  cptemp,
  rtptime,
  rtptemp,
  ballWeight,
  yeast,
}) {
  // calculate total ingredients
  const numPizzas = 1;
  const totalWeight = ballWeight * numPizzas;

  const flour = 100; // just so the next calculationis easier to understand
  const totalPercentage =
    parseFloat(hydration) +
    parseFloat(salt) +
    parseFloat(yeast) +
    parseFloat(flour);
  // const singlePercentage = totalPercentage / 100;

  const formulaConversionFactor = parseFloat(totalWeight) / totalPercentage; //total percentages / the desired weight

  const flourAmount = formulaConversionFactor * flour;

  const waterAmount = formulaConversionFactor * parseFloat(hydration);

  const saltAmount = formulaConversionFactor * parseFloat(salt);

  const yeastAmount = formulaConversionFactor * parseFloat(yeast);

  return (
    <section className="pizza__recipe-card">
      <h3 className="pizza__recipe-card-title">Recipe Card</h3>
      <h4 className="pizza__recipe-card-title">Neapolitan</h4>

      <section className="pizza__inputs">
        <div className="pizza__inputs-group">
          <label className="pizza__inputs-label">Hydration</label>
          <label className="pizza__inputs-item">{hydration}%</label>
        </div>
        <div className="pizza__inputs-group">
          <label className="pizza__inputs-label">Salt</label>
          <label className="pizza__inputs-item">{salt}%</label>
        </div>
        <div className="pizza__inputs-group">
          <label className="pizza__inputs-label">Cold Proofing Time</label>
          <label className="pizza__inputs-item">{cptime}hrs</label>
        </div>
        <div className="pizza__inputs-group">
          <label className="pizza__inputs-label">Warm Proofing Time</label>
          <label className="pizza__inputs-item">{rtptime}hrs</label>
        </div>
        <div className="pizza__inputs-group">
          <label className="pizza__inputs-label">Cold Proofing Temp</label>
          <label className="pizza__inputs-item">{cptemp}&deg;C</label>
        </div>
        <div className="pizza__inputs-group">
          <label className="pizza__inputs-label">Warm Proofing Temp</label>
          <label className="pizza__inputs-item">{rtptemp}&deg;C</label>
        </div>
        <div className="pizza__inputs-group">
          <label className="pizza__inputs-label">Individual ball weight</label>
          <label className="pizza__inputs-item">{ballWeight}g</label>
        </div>
        <div className="pizza__inputs-group">
          <label className="pizza__inputs-label">Yeast</label>
          <label className="pizza__inputs-item">{yeast}%</label>
        </div>
      </section>
      <div className="pizza__suggested-amounts">
        <p>To make 1 pizza (this scales linearly) you will need:</p>

        <div className="pizza__suggested-amounts-group">
          <label className="pizza__suggested-amounts-label">Flour</label>
          <label className="pizza__suggested-amounts-item">
            {Math.round((flourAmount + Number.EPSILON) * 100) / 100}g
          </label>
        </div>
        <div className="pizza__suggested-amounts-group">
          <label className="pizza__suggested-amounts-label">Water</label>
          <label className="pizza__suggested-amounts-item">
            {Math.round((waterAmount + Number.EPSILON) * 100) / 100}g
          </label>
        </div>
        <div className="pizza__suggested-amounts-group">
          <label className="pizza__suggested-amounts-label">Salt</label>
          <label className="pizza__suggested-amounts-item">
            {Math.round((saltAmount + Number.EPSILON) * 100) / 100}g
          </label>
        </div>
        <div className="pizza__suggested-amounts-group">
          <label className="pizza__suggested-amounts-label">Yeast</label>
          <label className="pizza__suggested-amounts-item">
            {Math.round((yeastAmount + Number.EPSILON) * 100) / 100}g
          </label>
        </div>
      </div>
    </section>
  );
}
