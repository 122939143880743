import React, { useEffect, useState } from "react";
import PizzaItem from "../../components/PizzaItem/PizzaItem";
import Loading from "../../components/Loading/Loading";
import { PIZZA_LIST_API } from "../../utils/api";
import axios from "axios";
import { db } from "../../firebase.js";

export default function PizzaDetails(props) {
  const [pizzaDetails, setPizzaDetails] = useState(null);

  const [pizza, setPizza] = useState([]);

  const getPizza = (pizzaId) => {
    db.collection("pizzas")
      .doc(pizzaId)
      .get()
      .then((docRef) => {
        console.log(docRef.id);

        let pizzaDoc = docRef.data();
        document.title = `Library of Pizza - ${pizzaDoc.pizzaName}`;
        let docID = docRef.id;
        let pizzaObject = { docID: docID, pizzaDoc, pizzaDoc };
        console.log(pizzaObject);
        setPizza(pizzaObject);
      });
  };

  useEffect(() => {
    let pizzaId = props.match.params.id;
    getPizza(pizzaId);

    // getPizzaDetails(pizzaId);
  }, [props.match.params.id]);

  // const getPizzaDetails = (pizzaId) => {
  //   axios
  //     .get(`${PIZZA_LIST_API}/${pizzaId}`)
  //     .then((response) => {
  //       setPizzaDetails(response.data);
  //     })
  //     .catch((err) =>
  //       console.log("Something went wrong while fetching the pizza data: ", err)
  //     );
  // };
  if (!pizza.pizzaDoc) {
    return <Loading />;
  }
  return (
    <div className="content">
      <PizzaItem pizzaDetails={pizza} />
    </div>
  );
}
